import React, { Fragment } from 'react';

import { formatNumber } from '../helpers';
import { useLanguage } from '../hooks/useLanguage';
import type { Language } from '../services/Language';

interface NumberProps {
  value: number;
  fractions?: number | [number, number];
  lang?: Language;
  prefix?: string;
  suffix?: string;
  negative?: boolean;
  as?: string;
  className?: string;
}

export default function NumberFormat(props: NumberProps) {
  const lang = useLanguage();

  let value = props.value;
  if (props.negative) {
    value = -value;
  }

  const Component = props.as ?? Fragment;
  let componentProps = {};
  if (props.as && props.className) {
    componentProps = { className: props.className };
  }
  return (
    <Component {...componentProps}>
      {props.prefix}
      {formatNumber(value, props.lang ?? lang, props.fractions)}
      {props.suffix}
    </Component>
  );
}
